:root {
  --cl-clean: #fff;
  --cl-dark: #000;
  --cl-text: #4a4a4f;
  --cl-link-visited: #847A5A;
  --cl-grey-10: #f9f9fa;
  --cl-grey-20: #eeeef1;
  --cl-grey-30: #d7d7db;
  --cl-grey-40: #b2b2b3;
  --cl-grey-50: #737373;
  --cl-grey-60: #4a4a4f;
  --cl-grey-70: #38383d;
  --cl-grey-80: #29292e;
  --cl-grey-90: #0c0c0d;
  --cl-sucess: #13bd00;
  --cl-info: #0a85ff;
  --cl-warning: #d66f00;
  --cl-error: #d60020;
  --cl-primary: #97ab21;
  --cl-secondary: #70653e;
  --cl-tertiary: #e77d04;
  --cl-primary-dark: #687817;
  --cl-secondary-light: #f0efec;
  --cl-secondary-xlight: #f9f9fa;
  --fz-uul: 4.5rem;
  --fz-ul: 4rem;
  --fz-hhhl: 3rem;
  --fz-hhl: 2.75rem;
  --fz-hl: 2.25rem;
  --fz-xxl: 2rem;
  --fz-xl: 1.75rem;
  --fz-l: 1.5rem;
  --fz-xxm: 1.25rem;
  --fz-xm: 1.125rem;
  --fz-m: 16px;
  --fz-s: 0.875rem;
  --fz-xs: 0.75rem;
  --fz-xxs: 0.625rem;
  --sp-quarter: 0.25rem;
  --sp-half: 0.5rem;
  --sp-unit: 1rem;
  --sp-and-half: 1.5rem;
  --sp-double: 2rem;
  --sp-triple: 3rem;
  --sp-quad: 4rem;
}

.c-phrase-home h4 {
  font-weight: 300;
  line-height: 1.2;
  color: var(--cl-primary);
  font-size: var(--fz-hl);
  margin-bottom: var(--sp-unit);
}

@media screen and (min-width: 993px) {
  .c-phrase-home h4 {
    font-size: var(--fz-hhhl);
    margin-bottom: var(--sp-double);
  }
}

.o-cta-call--home {
  max-width: 81.25rem;
  margin: 0 0.5rem;
}

.o-cta-call--home li::before {
  padding: 0 var(--sp-half);
  margin-left: -var(--sp-half);
}

@media screen and (min-width: 993px) {
  .o-cta-call--home {
    overflow: initial;
  }
  .o-cta-call--home ol {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: var(--sp-double);
  }
}

@media screen and (min-width: 1301px) {
  .o-cta-call--home {
    margin: 0 auto;
  }
}

.o-product-latest .block__title {
  position: relative;
  text-transform: uppercase;
  line-height: 1.2;
  color: var(--cl-primary-dark);
  font-size: var(--fz-hl);
  background-color: var(--cl-clean);
  box-shadow: -4px 0 0 var(--cl-clean);
  padding: 0;
}

.o-product-latest .block__title::before {
  content: '';
  display: block;
  width: 100%;
  height: 0.125rem;
  background-color: currentColor;
  position: absolute;
  top: 28px;
  left: -50%;
  z-index: -1;
}

.o-product-latest .o-product-list,
.o-product-latest .o-product-list--item:last-child {
  margin-bottom: 0;
}

.region-highlighted {
  margin-bottom: var(--sp-triple);
}

@media screen and (min-width: 993px) {
  .region-highlighted {
    margin-bottom: var(--sp-quad);
  }
}

.owl-carousel-block61 {
  position: relative;
}

.block-views-banners-block {
  margin-bottom: var(--sp-triple);
  background-color: var(--cl-secondary);
}

.view-banners {
  max-width: 81.25rem;
  margin: 0 auto;
}

.view-banners img {
  width: 100%;
  display: block !important;
}

.view-banners .owl-controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

@media screen and (min-width: 993px) {
  .view-banners img {
    height: 22.5rem;
  }
  .block-views-banners-block {
    margin-bottom: var(--sp-quad);
  }
}
