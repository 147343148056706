///*------------------------------------*\
//    #LAYOUTS - BANNERS
//\*------------------------------------*/

.owl-carousel-block61 { position: relative; }

.block-views-banners-block {
  margin-bottom: var(--sp-triple);
  background-color: var(--cl-secondary);
}

.view-banners {
  max-width: em(1300px);
  margin: 0 auto;
  
  img {
    width: 100%;
    display: block $i;
  }
  
  .owl-controls { @include centerer(bottom); }
}

@include media('screen', '>medium') {
  .view-banners img { height: em(360px); }
  
  .block-views-banners-block {
    margin-bottom: var(--sp-quad);
  }
}