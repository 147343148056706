// Variables
//
// Set variables for this site before a library sets its !default value.
//
// Style guide: sass.variables


// Browser support
//
// For older versions of browsers, some Sass relies on the `support-for()` to
// control whether extra CSS is needed to be output. The `support-for()`
// function and the `$support-for` variable are documented on the [support-for
// homepage](https://github.com/JohnAlbin/support-for).
//
// Style guide: sass.variables.support-for

// Support the same browsers as normalize.css v3.
// Possible keys: chrome, edge, firefox, ie, opera, safari, '*'
$support-for: (
  // IE 9-11
  ie:  9,
  // The last 4 versions of all other browsers.
  '*': -4,
);


// Font faces, stacks and sizes.
//
// Font styling and line heights are controlled by the several variables that
// used by mixins like `type-layout()`, `margin-top()`, and `margin-bottom()`.
// These variable and mixins are documented on the [Typey
// homepage](https://github.com/jptaranto/typey).
//
// Style guide: sass.variables.typey

// The font size set on the root html element.
$base-font-size:    16px;
$CONTEXT_FONT_SIZE: $base-font-size;

// The base line height determines the basic unit of vertical rhythm.
$base-line-height:  24px;

// The font sizes in our type hierarchy as tee shirt sizes.
$font-size: (
  uul:              72px,
  ul:               64px,
  hhhl:             48px,
  hhl:              44px,
  hl:               36px,
  xxl:              32px,
  xl:               28px,
  l:                24px,
  xxm:              20px,
  xm:               18px,
  m:                $base-font-size,
  s:                14px,
  xs:               12px,
  xxs:              10px
);

// Typey allows you to alter font weights site-wide with this map.
$font-weight: (
  bold:             700,
  medium:           500,
  normal:           400,
  light:            300,
  lighter:          lighter,
);

// The following font family declarations use widely available fonts.
// A user's web browser will look at the comma-separated list and will
// attempt to use each font in turn until it finds one that is available
// on the user's computer. The final "generic" font (sans-serif, serif or
// monospace) hints at what type of font to use if the web browser doesn't
// find any of the fonts in the list.

// Serif font stacks.
$times-new-roman: 'Times New Roman', Times, Georgia, 'DejaVu Serif', serif;
$times:           Times, 'Times New Roman', Georgia, 'DejaVu Serif', serif;
$georgia:         Georgia, 'Times New Roman', 'DejaVu Serif', serif;

// Sans-serif font stacks.
$roboto:          'Roboto', Tahoma, 'DejaVu Sans', sans-serif;
$worksans:        'Work Sans', Tahoma, 'DejaVu Sans', sans-serif;
$verdana:         Verdana, Tahoma, 'DejaVu Sans', sans-serif;
$tahoma:          Tahoma, Verdana, 'DejaVu Sans', sans-serif;
$helvetica:       Helvetica, Arial, 'Nimbus Sans L', sans-serif;
$arial:           Arial, Helvetica, 'Nimbus Sans L', sans-serif;
$impact:          Impact, 'Arial Narrow', Helvetica, sans-serif;

// Monospace font stacks.
// For an explanation of why "sans-serif" is at the end of this list, see
// http://meyerweb.com/eric/thoughts/2010/02/12/fixed-monospace-sizing/
$menlo:           Menlo, 'DejaVu Sans Mono', 'Ubuntu Mono', Courier, 'Courier New', monospace, sans-serif;

// The font faces you specify in the $typefaces map can be used in the
// typeface() mixin.
$typefaces: (
  body: (
    font-family: $worksans,
    weight: normal,
  ),
  read: (
    weight: 'light',
    letter-spacing: 0.3px
  ),
  displayh1: (
    weight: bold,
  ),
  monospace: (
    font-family: $menlo,
  ),
  compact: (
    font-family: $impact,
    weight: bold,
    case: uppercase,
    // We could also specify the following property:
    // letter-spacing: .5px,
  ),
);

// Output a horizontal grid to help with debugging typography.
$typey-debug: false;

// The length unit in which to output font size and margin values.
// Supported values: px, em, rem.
$base-unit:         'rem';

// px fallbacks for rem units are needed for IE 8 and earlier.
$rem-fallback:      false;


// Breakpoints
//
// Use the `respond-to()` mixin to use named breakpoints. Documentation is
// available in the [Breakpoint wiki
// pages](https://github.com/at-import/breakpoint/wiki/Respond-To).
//
// Style guide: sass.variables.breakpoints

@include add-breakpoint(xxxs, (333px, 'no-query' true));
@include add-breakpoint(xxs,  (444px, 'no-query' true));
@include add-breakpoint(xs,   (555px, 'no-query' true));
@include add-breakpoint(s,    (666px, 'no-query' true));
@include add-breakpoint(m,    (777px, 'no-query' true));
@include add-breakpoint(l,    (888px, 'no-query' true));
@include add-breakpoint(xl,   (999px, 'no-query' true));
@include add-breakpoint(xxl,  (1111px));
@include add-breakpoint(xxxl, (1222px));

// Output media queries where the above px are converted to ems.
// @include breakpoint-set('to ems', true);


// Zen grids
//
// The default grid system is built using the Zen Grids sass module. Full
// documentation is available on the [Zen Grids website](http://zengrids.com/).
//
// Note: if you are more comfortable using another grid system, you can easily
// remove Zen Grids and its layouts.
//
// Style guide: sass.variables.zen-grids

// These are needed for all breakpoints on all layouts.
$zen-auto-include-grid-item-base: false;
$zen-box-sizing: universal-border-box;

// First we set the values we need for the mobile version of our layouts (the
// version that does _not_ appear inside any @media queries.)
$zen-columns: 1;
$zen-gutters: 40px;

// Then we set the values we need for each of the layouts at larger media query
// sizes. Note how the names of the layouts match the names of our breakpoints
// above. That allows a single zen-respond-to() to both use the proper item
// from $zen-layouts and from $breakpoints.
$zen-layouts: (
  m: (
    columns: 2,
    // The gutters in the layouts are larger on mobile.
    gutters: 25px,
  ),
  xl: (
    columns: 3,
    gutters: 25px,
  ),
);

// See $include-rtl below.
$zen-rtl-selector: '[dir="rtl"]';


// Miscellaneous variables
//
// `$indent-amount` controls the amount lists, blockquotes and comments are indented.
//
// `$include-rtl` controls whether RTL styles are output. Rather than include a separate `*-rtl.css` file, Zen 6 uses Drupal 8's standard `[dir="rtl"]` selector for RTL language support.
//
// weight: 10
//
// Style guide: sass.variables.misc

$indent-amount: 2 * $base-font-size;

$include-rtl: true;
// Automatically turn off Zen Grids' RTL support if $include-rtl is false.
@if not $include-rtl {
  $zen-rtl-selector: false !global;
}




///*----------------------------------*\
//  #SETTINGS
//\*----------------------------------*/

//== Helpers
$i:           !important;
$t:           transparent;
$n:           none;
$in:          initial;
$ih:          inherit;
$cc:          currentColor;
$imgPath:     '../images';

$w-l: 1300px;

//== prefix for namespaces
$prefix-object:    'o-';
$prefix-component: 'c-';
$prefix-utility:   'u-';
$prefix-theme:     't-';
$prefix-scope:     's-';

$prefix-is:        'is-';
$prefix-has:       'has-';

/// Line height default from the Bootstrap
/// @group Typography
/// @prop {Number} $line-height-text [1.3]
/// @prop {Number} $line-height-reset [1]
$line-height-text: 1.3;
$line-height-reset: 1;


//== Defaults
$pad:   1rem;
$space: $pad;

$space-quarter:         $space/4;
$space-half:            $space/2;
$space-and-half:        $space*1.5;
$space-double:          $space*2;
$space-triple:          $space*3;
$space-quad:            $space*4;

$pad-quarter:           $pad/4;
$pad-half:              $pad/2;
$pad-and-half:          $pad*1.5;
$pad-double:            $pad*2;
$pad-triple:            $pad*3;
$pad-quad:              $pad*4;

//== Components
$border-radius-circle: 50%;

$vars-project: (
  // colors
  // d = default
  --cl-clean:        #fff,
  --cl-dark:         #000,
  --cl-text:         #4a4a4f,
  --cl-link-visited: #847A5A,
  // n = neutral
  --cl-grey-10:      #f9f9fa,
  --cl-grey-20:      #eeeef1,
  --cl-grey-30:      #d7d7db,
  --cl-grey-40:      #b2b2b3,
  --cl-grey-50:      #737373,
  --cl-grey-60:      #4a4a4f,
  --cl-grey-70:      #38383d,
  --cl-grey-80:      #29292e,
  --cl-grey-90:      #0c0c0d,
  // s = semantic
  --cl-sucess:       #13bd00,
  --cl-info:         #0a85ff,
  --cl-warning:      #d66f00,
  --cl-error:        #d60020,
  // m = main
  --cl-primary:      #97ab21,
  --cl-secondary:    #70653e,
  --cl-tertiary:     #e77d04,
  // p = palettes
  --cl-primary-dark:     #687817,
  --cl-secondary-light:  #f0efec,
  --cl-secondary-xlight: #f9f9fa,
  // font-sizes
  --fz-uul:    4.5rem,   // 72px
  --fz-ul:     4rem,     // 64px
  --fz-hhhl:   3rem,     // 48px
  --fz-hhl:    2.75rem,  // 44px
  --fz-hl:     2.25rem,  // 36px
  --fz-xxl:    2rem,     // 32px
  --fz-xl:     1.75rem,  // 28px
  --fz-l:      1.5rem,   // 24px
  --fz-xxm:    1.25rem,  // 20px
  --fz-xm:     1.125rem, // 18px
  --fz-m:      $base-font-size,
  --fz-s:      0.875rem, // 14px
  --fz-xs:     0.75rem,  // 12px
  --fz-xxs:    0.625rem, // 10px
  // spaces
  --sp-quarter:  $space/4, // 4px
  --sp-half:     $space-half, // 8px
  --sp-unit:     $space, // 16px
  --sp-and-half: $space-and-half, // 24px
  --sp-double:   $space-double, // 32px
  --sp-triple:   $space-triple, // 48px
  --sp-quad:     $space-quad, // 64px
);

// use css vars 
// https://codepen.io/vank0/pen/kkGvAb
// use mixin and functions .scss
$css4: true;
$compatibility: true;

//== Breakpoints
$breakpoints: (
  xsmall: 480px,
  small:  768px,
  medium: 992px,
  large:  1300px,
  xlarge: 1400px
);


//== Time
$duration-instantly:    0s;
$duration-immediately:  0.05s;
$duration-quickly:      0.1s;
$duration-promptly:     0.2s;
$duration-slowly:       0.4s;
$duration-paused:       3.2s;













