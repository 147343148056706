///*------------------------------------*\
//    #MIXINS
//\*------------------------------------*/

// use css vars 
// https://codepen.io/vank0/pen/kkGvAb
//the mixin temporally sets the $css4 variable to false, compiles the css3 fallback, then makes the variable true again and compiles the css4 code. It should contain properties that use css4 variables, otherwise there will be unnessesary duplication of properties.
@mixin css4 {
  @if ($css4) {
    $css4-backup: $css4;
    @if($compatibility) {
      $css4: false !global;
      @content;
    }
    $css4: true !global;
    @content;
    $css4: $css4-backup;
  } 
  @else {
    @content;
  }
}

// Feature Detection
//
// $feature - Feature-specific class, i.e. `cssgradients`.
// @usage - `@include supported($feature)`
// @usage - `@include not-supported($feature)`

@mixin supported($feature) {
  .#{$feature} & {
    @content;
  }
}

@mixin not-supported($feature) {
  .no-js &,
  .no-#{$feature} & {
    @content;
  }
}


// Color Scheme 0.0.2
// Scheme from colors for create classes with properties css, functions for facilitie use in the project like palette, tint, shade, etc
// From DEV Undergrond
// @authors: Jonas Sousa, Allef Silva

/// make-classes-map - Create utilities classes from the maps variables
/// @author Jonas Sousa, Allef Silva
/// @group Tools
/// @param {Map} $map - Variable with maps for be transformed in classes
/// @param {String} $prefix - Namespace for the organize names
/// @param {String} $name - Radical word from name
/// @param {String} $prop - properties names from classes 
/// @example scss - basic usage
///   @include make-classes-map($cls-default, $prefix-utilities, $radical-classes-color, $propertie-color);
///
/// @output Output from the example
///   .u-color--text { color: #121212; }
///   .u-color--bgc { color: white; }
@mixin make-classes-map($map, $prefix, $name, $prop){
  @each $sufix, $value in $map {
    .#{$prefix}#{$name}--#{$sufix} {
      #{$prop}: use-map($map, $sufix);
    }
  }
}

//*----------------------------------*\
//  SIZE
//\*----------------------------------*/

/// Size - Set propertys width and height
/// @author Hugo Giraudel
/// @group Tools
/// @param {Number} $width - Property width css
/// @param {Number} $height [$width] - Default value width, but it's possible use diferent height
/// @example scss - basic usage
///   .square {
///     @include size(50px);
///   }
/// @output Output from the example
///   .square {
///     width: 50px;
///     height: 50px;
///   }

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

//*----------------------------------*\
//  HOVER
//\*----------------------------------*/

/// Hover - Create pseduo-classes in link: `:hover`, `:active`, and `:focus`.
/// @author Hugo Giraudel
/// @group Tools
/// @content propertys in `:hover`, `:active`, and `:focus` link.
/// @example scss - basic usage
///   .simple-link {
///     ...
///     @include hover {
///       ...
///     }
///   }

@mixin hover {
  &:hover,
  &:active,
  &:focus { @content; }
}

//*----------------------------------*\
//  ARROW
//\*----------------------------------*/

/// Arrow - Create a arrow in the direction your preference.
/// Direction must be one of top, right, bottom or left.
/// @author CSS-Tricks
/// @link https://css-tricks.com/snippets/sass/css-triangle-mixin/
/// @group Tools
/// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
/// @param {Color} $color [currentcolor] - Triangle color
/// @param {Length} $size [1em] - Triangle size
/// @example scss - basic usage
///   .foo::before {
///     @include triangle(bottom);
///     display: inline-block; // d:ib || pos:a - use one property for your propouse
///   }
/// @output Output from the example
///   .foo::before {
///     width: 0;
///     height: 0;
///     content: '';
///     z-index: 2;
///     border-top: 1.5em solid currentcolor;
///     border-left: 1em solid transparent;
///     border-right: 1em solid transparent;
///     display: inline-block; 
///   }

@mixin triangle($direction, $color: currentcolor, $size: 1em) {
  @if not index(top right bottom left, $direction) {
    @error "Direction must be either `top`, `right`, `bottom` or `left`.";
  }

  content: '';
  width: 0;
  height: 0;
  z-index: 2; // if you use pos:a

//  border-#{opposite-position($direction)}: ($size * 1.5) solid $color;
  border-#{$direction}: ($size * 1.5) solid $color;

  $perpendicular-borders: $size solid transparent;
  
  @if $direction == top or $direction == bottom {
    border-left:   $perpendicular-borders;
    border-right:  $perpendicular-borders;
  } @else if $direction == right or $direction == left {
    border-bottom: $perpendicular-borders;
    border-top:    $perpendicular-borders;
  }
}

//*----------------------------------*\
//  CENTERER
//\*----------------------------------*/

/// Centerer - Position elements centered in directions especific or all
/// @author CSS-Tricks
/// @link https://css-tricks.com/snippets/sass/centering-mixin/ In the comments have a optimization this mixin
/// @group Tools
/// @param {String} $pos [both] - Default functions is centered in all directions, but is possible choice side
/// @example scss - basic usage
///   .centered {
///     @include centerer();
///   }
/// @output Output from the example
///   .centered {
///     position: absolute;
///     top: 50%;
///     left: 50%;
///     transform: translate(-50%, -50%);
///   }

@mixin centerer($pos:both) {
  position: absolute;

  @if ($pos==both) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  } @else if ($pos==top) {
    left: 50%;
    transform: translate(-50%, 0);

  } @else if ($pos==left) {
    top: 50%;
    transform: translate(0, -50%);

  } @else if ($pos==right) {
    top: 50%;
    right:0;
    transform: translate(0, -50%);

  } @else if ($pos==bottom) {
    bottom: 0;
    left:50%;
    transform: translate(-50%, 0);
  }
}


// Truncate text
// If the text is longer than one line, it will be truncated and end with an ellipsis(...).
@mixin truncate-text( $tru-w ) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: $tru-w;
}

@mixin flexbox-centering {
  display: flex;
  justify-content: center;
  align-items: center;
}


@mixin no-link {
  cursor: $cursor-disabled;
  pointer-events: none; // Future-proof disabling of clicks
}

@mixin mobile-scroll-horizontal {
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

@mixin clear-list {
  list-style: none;
  padding: 0;
}

// 
// mixins for container
@mixin o-wrapper {
  //  @include clearfix();
  width: 100%;
  max-width: em($w-l);
  padding-right: 1rem;
  padding-left:  1rem;
  margin-right: auto;
  margin-left:  auto;
  
  @include media('screen', '>=small') {
    padding-right: 1.5rem;
    padding-left:  1.5rem;
  }
  
  @include media('screen', '>large') {
    padding: 0;
  }
}

@mixin horizontal {
  display: flex;
  align-items: center;
}

@mixin list-horizontal {
  @include horizontal;
  flex-wrap: wrap;  
}

@mixin label {
  text-transform: uppercase;
  color: var(--cl-grey-50);
  font-size: var(--fz-xs);
  font-weight: 700;
  line-height: $line-height-reset;
}

@mixin box-dropdown {
  width: em(320px);
  border: 2px solid var(--cl-secondary);
  background-color: var(--cl-secondary-xlight);
  position: relative;
  
  &::before {
    @include triangle(bottom, #70653e, 6px);
    position: absolute;
    top: -(em(9px));
    right: em(16px);
  }
}

// use in the link
@mixin arrow-right {
  &::before {
    content: '';
    display: block;
    @include size(em(16px));
    background: url('#{$imgPath}/arrow-right-brown.svg') 0 center no-repeat;
    @include transition(all $duration-quickly ease-in-out);
  }

  @include hover {
    &::before { background-position: center; }
  }
}








