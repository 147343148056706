///*----------------------------------*\
//  #FUNCTIONS
//\*----------------------------------*/

// use css vars 
// https://codepen.io/vank0/pen/kkGvAb
//this is the "magic" function
@function var($variable) {
  @if($css4) {
    @return unquote('var(' + $variable + ')');
  } @else {
    @return map-get($vars-project, $variable);
  }
}

// Color Scheme 0.0.2
// Scheme from colors for create classes with properties css, functions for facilitie use in the project like palette, tint, shade, etc
// From DEV Undergrond
// @authors: Jonas Sousa, Allef Silva

/// use-map - Extract and use a value in the map variable
/// @author Jonas Sousa, Allef Silva
/// @group Tools
/// @param {Map} $map - Variable with maps for use
/// @param {String} $value - Value map 
/// @example scss - basic usage
///   .foo {
///     color: use-map($cls-main, primary);
///   }
///
/// @output Output from the example
///   .foo {
///     color: #121212;
///   }
@function use-map($map, $value){
  @if map-has-key($map, $value) {
    @return map-get($map, $value);
  }
  
  @warn "Unfortunately, no value could be retrieved from `#{$value}`. "
        + "Please make sure it is defined in `#{$map}` map.";
  @return null;
}

// Returns unitless number
@function remove-unit($number) {
  $unit: unit($number);
  $one: 1;

  @if $unit == "px" { $one: 1px; }
  @if $unit == "rem" { $one: 1rem; }
  @if $unit == "%"  { $one: 1%;  }

  @return $number / $one;
}

/// Returns flexible value using `target ÷ context` formula.
/// Returns `em` by default, accepts `%` as format.
/// @group Helpers
/// @author Eduardo Shiota
/// @link https://github.com/eshiota/eshiota_middleman_blog/blob/master/source/stylesheets/base/_functions.scss
@function flex($target, $context: $CONTEXT_FONT_SIZE, $unit: "rem") {
  $size: remove-unit($target) / remove-unit($context);

  @if $unit == "" { @return $size; }
  @if $unit == "rem" { @return #{$size}rem; }
  @if $unit == "%" { @return percentage($size); }
}

/// Alias to `flex` function, using `%` as format.
/// @group Helpers
/// @author Eduardo Shiota
/// @link https://github.com/eshiota/eshiota_middleman_blog/blob/master/source/stylesheets/base/_functions.scss
/// @arg {Number} $target - Width target use
/// @arg {Number} $context - Target block parent
@function perc($target, $context) {
  @return flex($target, $context, "%");
}

/// Alias to `flex` function, using `em` as format.
/// @group Typography
/// @author Eduardo Shiota
/// @link https://github.com/eshiota/eshiota_middleman_blog/blob/master/source/stylesheets/base/_functions.scss
/// @arg {Number} $target - Font size
/// @arg {Number} $context - Use defaut '$CONTEXT_FONT_SIZE' 16px
@function em($target, $context: $CONTEXT_FONT_SIZE) {
  @return flex($target, $context, "rem");
}