///*------------------------------------*\
//    #LAYOUTS - PAGE FRONT
//\*------------------------------------*/




// 
// elements
.c-phrase-home h4 {
  font-weight: 300;
  line-height: 1.2;
  color: var(--cl-primary);
  font-size: var(--fz-hl);
  margin-bottom: var(--sp-unit);
  
  @include media('screen', '>medium') {
    font-size: var(--fz-hhhl);
    margin-bottom: var(--sp-double);
  }
}


// 
// blocks
.o-cta-call--home {
  max-width: em(1300px);
  margin: 0 em(8px);
  
  li::before {
    padding: 0 var(--sp-half);
    margin-left: - var(--sp-half);
  }
  
  // Desktop
  @include media('screen', '>medium') {
    overflow: $in;
    
    ol {
      margin-top: 0;
      padding-top: 0;
      padding-bottom: var(--sp-double);
    }
  }
  
  @include media('screen', '>large') {
    margin: 0 auto;
  }
}

.o-product-latest { // product-teaser
  .block__title {
    position: relative;
    text-transform: uppercase;
    line-height: 1.2;
    color: var(--cl-primary-dark);
    font-size: var(--fz-hl);
    background-color: var(--cl-clean);
    box-shadow: -4px 0 0 var(--cl-clean);
    padding: 0;

    &::before {
      content: '';
      display: block;
      @include size(100%, em(2px));
      background-color: $cc;
      position: absolute;
      top: 28px;
      left: -50%;
      z-index: -1;
    }
  }
  
  .o-product-list, 
  .o-product-list--item:last-child { margin-bottom: 0; }
}


// 
// regions
.region-highlighted {
  margin-bottom: var(--sp-triple);
  
  @include media('screen', '>medium') {
    margin-bottom: var(--sp-quad);
  }
}

// .front {
//   .o-cta-call-header {
//     text-align: $ih;
    
//     em {
//         font-size: var(--fz-xxm);
//     }
    
//     h4 {
//       line-height: 1.1;
//       margin-bottom: 0;
//     }
//   }
  
//   .o-cta-call-footer {
//     text-align: $ih;
//     margin: 0;
    
//     &__item {
//       padding: 0;
//       margin: 0;
//     }
//   }
  
//   .o-main__content {
//     grid-area: $in;
//     grid-column: 1 / span 4;
//   }
  
  
//   @include media('screen', '>medium') {
//     .o-cta-call-footer__txt { padding-left: em(12px); }
    
//     .o-cta-call-header {
//       width: 33.33%;
//       margin-bottom: 0;
//     }
    
//     .o-cta-call-footer {
//       @include horizontal;
//       width: 66.66%;
//     }
    
//     .o-sidebar-second {
//       grid-area: $ih;
//       grid-column: 3 / span 3;

//       display: flex;
//       flex-wrap: wrap;
//       align-items: flex-end;
//     }
//   }
// }