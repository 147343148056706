// use css vars 
// https://codepen.io/vank0/pen/kkGvAb
// use mixin and functions .scss
//$css4: true;
//$compatibility: true;
//Here we transfer the variables from the map to the :root element
@if($css4) {
  :root {
    @each $variable, $value in $vars-project {
      #{$variable}: $value;
    }
  }
}